import { Inject, Injectable } from '@angular/core'
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy'
import { tapResponse } from '@ngrx/operators'
import { AppwriteException, ID, Models } from 'appwrite'
import {
    BehaviorSubject,
    Observable,
    catchError,
    from,
    map,
    of,
    switchMap,
    tap,
    throwError,
} from 'rxjs'
import { AuthStateInterface } from 'src/app/auth/interfaces/authState.interface'
import { LoginRequest } from 'src/app/auth/store/states/login.state'
import { ChangePasswordRequest } from 'src/app/auth/store/states/recover.state'
import { RegisterRequestInterface } from 'src/app/auth/store/states/register.state'
import { AuthenticatedInterface } from 'src/app/shared/interfaces/authenticated.interface'
import { Country } from 'src/app/shared/interfaces/country.interface'
import {
    CurrentUserInterface,
    UserPreferenceInterface,
} from 'src/app/shared/interfaces/currentUser.interface'
import { LogType } from 'src/app/shared/interfaces/log.interface'
import {
    APPWRITE_SDK,
    AppwriteSdk,
} from 'src/app/shared/services/appwrite.provider'
import { LogService } from 'src/app/shared/services/log.service'
import { NotificationService } from 'src/app/shared/services/notification.service'
import Utils from 'src/app/shared/services/utils'
import { environment } from 'src/environments/environment'

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class AuthService {
    constructor(
        @Inject(APPWRITE_SDK)
        private _appWrite: AppwriteSdk,
        private _notification: NotificationService,
        private _logService: LogService
    ) {}

    private isLoggedIn$: BehaviorSubject<boolean> = new BehaviorSubject(false)
    private isUserEmailVerified$: BehaviorSubject<boolean> =
        new BehaviorSubject(false)
    private isAdmin$: BehaviorSubject<boolean> = new BehaviorSubject(false)

    public get isLoggedIn(): Observable<boolean> {
        return this.isLoggedIn$
    }
    public get isUserEmailVerified(): Observable<boolean> {
        return this.isUserEmailVerified$
    }

    public get isAdmin(): Observable<boolean> {
        return this.isAdmin$
    }

    register(data: RegisterRequestInterface): Observable<CurrentUserInterface> {
        return from(
            this._appWrite.account.create(
                ID.unique(),
                data.email,
                data.password
            )
        ).pipe(
            map((account) => {
                return convertAccountToUser(account)
            }),
            catchError((error) => {
                return this.handleErrors(error)
            }),
            untilDestroyed(this)
        )
    }

    getCountries(): Observable<Country[]> {
        return new Observable((subscriber) => {
            let list: Country[] = []
            from(
                this._appWrite.locale.listCountries().then((values) => {
                    list = values.countries as Country[]
                    subscriber.next(list)
                    subscriber.complete()
                })
            ).pipe(
                catchError((error) => {
                    return this.handleErrors(error)
                }),
                untilDestroyed(this)
            )
        })
    }

    login(request: LoginRequest): Observable<AuthStateInterface> {
        if (!request.email || !request.password) {
            this._notification.notifyFailure(
                'Invalid login email and password are required'
            )
            throw new Error(
                'Invalid login request: email and password are required'
            )
        }

        return from(
            this._appWrite.account.createEmailPasswordSession(
                request.email,
                request.password
            )
        ).pipe(
            switchMap((session) => this._appWrite.account.get()),
            map((account) => {
                this.isLoggedIn$.next(true)
                this.isUserEmailVerified$.next(account.emailVerification)
                return convertAccountToAuthState(account)
            }),
            catchError((error) => this.handleErrors(error)),
            untilDestroyed(this)
        )
    }

    sendResetPasswordEmail(email: string): Observable<Models.Token> {
        console.log('recover request', email)

        return from(
            this._appWrite.account.createRecovery(
                email,
                environment.recover_password_url
            )
        ).pipe(
            tap((token) => console.log('token', token)),
            catchError((error) => {
                return this.handleErrors(error)
            }),
            untilDestroyed(this)
        )
    }

    updatePassword(request: ChangePasswordRequest): Observable<Models.Token> {
        console.log('change password request', request)

        return from(
            this._appWrite.account.updateRecovery(
                request.userId,
                request.secret,
                request.password
            )
        ).pipe(
            tap((token) => console.log('token', token)),
            catchError((error) => {
                return this.handleErrors(error)
            }),
            untilDestroyed(this)
        )
    }

    isAuthenticated(): Observable<AuthenticatedInterface> {
        return from(this._appWrite.account.get()).pipe(
            switchMap((acc) => {
                this.isLoggedIn$.next(true)
                this.isUserEmailVerified$.next(acc.emailVerification)
                const isAdmin = Utils.stringToBoolean(acc.prefs?.['admin']) === true
                this.isAdmin$.next(isAdmin)
                return of({
                    auth: convertAccountToAuthState(acc),
                    authenticated: true,
                } as AuthenticatedInterface)
            }),
            catchError((error: AppwriteException) => {
                this._logService.writeLogAsync({
                    logType: LogType.Error,
                    message: error.message,
                })
                this.isLoggedIn$.next(false)
                this.isUserEmailVerified$.next(false)
                this.isAdmin$.next(false)
                return of({
                    auth: {
                        currentUser: null,
                        userPreferences: null,
                    },
                    authenticated: false,
                } as AuthenticatedInterface)
            }),
            untilDestroyed(this)
        )
    }

    updateUserPreferences(
        prefs: UserPreferenceInterface
    ): Observable<UserPreferenceInterface> {
        return from(this._appWrite.account.updatePrefs(prefs)).pipe(
            map((account) => convertAccountToUserPreferences(account)),
            catchError((error) => {
                return this.handleErrors(error)
            }),
            untilDestroyed(this)
        )
    }
    updateUserName(
        firstName: string,
        lastName: string
    ): Observable<CurrentUserInterface> {
        return from(
            this._appWrite.account.updateName(firstName + '|' + lastName)
        ).pipe(
            map((account) => convertAccountToUser(account)),
            catchError((error) => {
                return this.handleErrors(error)
            }),
            untilDestroyed(this)
        )
    }

    logout(): Observable<boolean> {
        return from(this._appWrite.account.deleteSession('current')).pipe(
            switchMap(() => {
                this.isLoggedIn$.next(false)
                this.isUserEmailVerified$.next(false)
                this._logService.writeLogAsync({
                    logType: LogType.Info,
                    message: 'user logged out successfully!',
                })
                return of(true)
            }),
            catchError((error) => {
                console.log('user logout error: ', error.message)
                return this.handleErrors(error)
            })
        )
    }

    handleErrors(error: Error | AppwriteException): Observable<never> {
        let message = Utils.handleErrorMessage(error)
        this._logService.writeLogAsync({
            message: message,
            logType: LogType.Error,
        })
        return throwError(() => error)
    }
}

function convertAccountToUser(
    account: Models.User<Models.Preferences>
): CurrentUserInterface {
    let firstName = ''
    let lastName = ''
    if (account.name) {
        switch (account.name.split('|').length) {
            case 1:
                firstName = account.name
                break
            case 2:
                firstName = account.name?.split('|')[0]
                lastName = account.name?.split('|')[1]
                break
            default:
                console.log('error formatting name from account')
                break
        }
    }
    return {
        userID: account.$id,
        email: account.email,
        firstName: firstName,
        lastName: lastName,
        phone: account.phone,
        status: account.status,
        createdAt: account.$createdAt,
        passwordUpdateDate: account.passwordUpdate,
        updatedAt: account.$updatedAt,
        registrationDate: account.registration,
        emailVerification: account.emailVerification,
        phoneVerification: account.phoneVerification,
        isAdmin: account.prefs?.['admin'] === true,
    }
}
function convertAccountToUserPreferences(
    account: Models.User<Models.Preferences>
): UserPreferenceInterface {
    return {
        phone: account.prefs['phone'],
        has_address: account.prefs['has_address'],
        avatar_id: account.prefs['avatar_id'],
    }
}

function convertAccountToAuthState(
    account: Models.User<Models.Preferences>
): AuthStateInterface {
    const currentUser = convertAccountToUser(account)
    const prefs = convertAccountToUserPreferences(account)
    return {
        currentUser: currentUser,
        userPreferences: prefs,
        isUserAuthenticated: true,
    }
}
