import { Inject, Injectable } from '@angular/core'
import { AppwriteException, Models, Query } from 'appwrite'
import { Observable, catchError, from, tap, throwError } from 'rxjs'
import { PhotoOperation } from 'src/app/masjids/interfaces/masjid.state.interface'
import {
    APPWRITE_SDK,
    AppwriteSdk,
} from 'src/app/shared/services/appwrite.provider'
import { environment } from 'src/environments/environment'
import { PhotoType } from '../interfaces/Image.interface'
import { LogType } from '../interfaces/log.interface'
import { LogService } from './log.service'
import Utils from './utils'

@Injectable({
    providedIn: 'root',
})
export class FileStorageService {
    constructor(
        @Inject(APPWRITE_SDK) private _appwrite: AppwriteSdk,
        private _logService: LogService
    ) {}

    avatarExists(fileID: string): Observable<boolean> {
        return from(
            this._appwrite.storage
                .listFiles(environment.users_avatar_bucket_id, [
                    Query.equal('$id', fileID),
                ])
                .then((response) => {
                    if (response.total > 0) {
                        return true
                    }
                    return false
                })
        ).pipe(
            catchError((error) => {
                return this.handleErrors(error)
            })
        )
    }

    getAvatarPreviewUrl(userID: string): string {
        return this._appwrite.storage.getFilePreview(
            environment.users_avatar_bucket_id,
            userID
        )
    }

    getFilePreviewUrl(bucketId: string, fileId: string): string {
        return this._appwrite.storage.getFileView(bucketId, fileId)
    }

    deleteAvatarImg(fileId: string): Observable<{}> {
        return from(
            this._appwrite.storage.deleteFile(
                environment.users_avatar_bucket_id,
                fileId
            )
        ).pipe(
            catchError((error) => {
                return this.handleErrors(error)
            })
        )
    }

    addAvatarImg(file: File, fileID: string): Observable<Models.File> {
        return from(
            this._appwrite.storage.createFile(
                environment.users_avatar_bucket_id,
                fileID,
                file
            )
        ).pipe(
            catchError((error) => {
                return this.handleErrors(error)
            })
        )
    }

    deleteMasjidImg(fileId: string): Observable<PhotoOperation> {
        return from(
            this._appwrite.storage
                .deleteFile(environment.masjid_images_bucket, fileId)
                .then((res) => {
                    console.log(
                        `masjid file has been deleted successfully file: ${fileId}`,
                        res
                    )
                    return {
                        operation: 'delete',
                        imageType: PhotoType.Masjid,
                        id: fileId,
                    } as PhotoOperation
                })
        ).pipe(
            catchError((error) => {
                return this.handleErrors(error)
            })
        )
    }
    deleteMasjidLeaderImg(fileId: string): Observable<PhotoOperation> {
        return from(
            this._appwrite.storage
                .deleteFile(environment.masjid_leader_images_bucket, fileId)
                .then((res) => {
                    console.log(
                        `masjid leader file has been deleted successfully file: ${fileId}`,
                        res
                    )
                    return {
                        operation: 'delete',
                        imageType: PhotoType.Leader,
                        id: fileId,
                    } as PhotoOperation
                })
        ).pipe(
            catchError((error) => {
                return this.handleErrors(error)
            })
        )
    }

    generateAvatarImageID(userID: string): string {
        return userID + '-' + Date.now().toString()
    }

    uploadFile(
        file: File,
        id: string,
        bucketId: string
    ): Observable<Models.File> {
        return from(this._appwrite.storage.createFile(bucketId, id, file)).pipe(
            tap((result) => {
                console.log(
                    `File ${id} has been uploaded successfully.`,
                    result
                )
            }),
            catchError((error) => {
                return this.handleErrors(error)
            })
        )
    }

    handleErrors(error: Error | AppwriteException): Observable<never> {
        let message = Utils.handleErrorMessage(error)
        this._logService.writeLogAsync({
            message: message,
            logType: LogType.Error,
        })
        return throwError(() => error)
    }
}
