import { Injectable } from '@angular/core'
import {
    ActivatedRouteSnapshot,
    Router,
    RouterStateSnapshot,
    UrlTree,
} from '@angular/router'
import { Store } from '@ngrx/store'
import { Observable, map, tap } from 'rxjs'
import { refreshAuthenticatedUserDataAction } from 'src/app/auth/store/actions/refresh.actions'
import { RETURN_URL_QUERY_PARAM } from 'src/app/auth/store/states/login.state'
import { AuthService } from 'src/app/shared/services/auth.service'
import { AuthenticatedInterface } from '../interfaces/authenticated.interface'

@Injectable({
    providedIn: 'root',
})
export class AuthenticatedGuard {
    constructor(
        private _authService: AuthService,
        private router: Router,
        private store: Store
    ) {}
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        return this._authService.isAuthenticated().pipe(
            tap((response) => {
                if (!response.authenticated) {
                    console.log(
                        'not authenticated - redirecting, origin:',
                        state.url
                    )
                    console.log('not authenticated :', response)

                    // saving the current url to query parameter to be used after login.
                    this.router.navigate(['/auth/login'], {
                        queryParams: {
                            [RETURN_URL_QUERY_PARAM]: state.url,
                        },
                    })
                } else {
                    // update the store with the auth data
                    console.log('is authenticated: ', response)
                    this.store.dispatch(
                        refreshAuthenticatedUserDataAction({
                            auth: {
                                auth: response.auth,
                                authenticated: response.authenticated,
                            },
                        })
                    )
                }
            }),
            map((auth: AuthenticatedInterface) => auth.authenticated)
        )
    }
}
