import { HttpErrorResponse } from '@angular/common/http'
import { FormControl, FormGroup } from '@angular/forms'
import { AppwriteException } from 'appwrite'
import { Observable, Observer } from 'rxjs'
import { Country } from 'src/app/shared/interfaces/country.interface'
import { PhotoInterface, PhotoType } from '../interfaces/Image.interface'

export default class Utils {
    static validateAllFormFields(formGroup: FormGroup): void {
        Object.keys(formGroup.controls).forEach((field) => {
            const control = formGroup.get(field)
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true })
            } else if (control instanceof FormGroup) {
                Utils.validateAllFormFields(control)
            }
        })
    }
    static convertBytesToMegaByte(number: number): number {
        return number / 1024 / 1024
    }
    static arrayRange(start: number, end: number): Array<number> {
        return Array.from({ length: end - start }, (v, k) => k + start)
    }
    static getDifferenceDateTime(startDate: Date, endDate: Date) {
        var diff = endDate.getTime() - startDate.getTime()
        var days = Math.floor(diff / (60 * 60 * 24 * 1000))
        var hours = Math.floor(diff / (60 * 60 * 1000)) - days * 24
        var minutes =
            Math.floor(diff / (60 * 1000)) - (days * 24 * 60 + hours * 60)
        var seconds =
            Math.floor(diff / 1000) -
            (days * 24 * 60 * 60 + hours * 60 * 60 + minutes * 60)
        return { day: days, hour: hours, minute: minutes, second: seconds }
    }

    /**
     * Generates a unique ID based on timestamp and random number
     * Used for correlation IDs and other identifiers
     */
    static generateUniqueId(): string {
        const date: Date = new Date()
        const uniqueId: string = `${date.getFullYear()}${date.getMonth()}${date.getDate()}${date.getHours()}${date.getMinutes()}${date.getSeconds()}${date.getMilliseconds()}`
        const random: string = Math.floor(Math.random() * 10000).toString()
        return `${uniqueId}${random}`
    }

    /**
     * Generates a correlation ID for logging issues and tracking events
     * @param {string | null} userId - The user's unique ID, or null if not logged in
     * @returns {string} A unique correlation ID
     */
    static generateCorrelationId(userId: string | null): string {
        const extraSuffix = Date.now().toString(36).slice(-5)
        if (userId) {
            return `${userId}-${extraSuffix}`
        } else {
            return `anonymous-${Utils.generateUniqueId()}`
        }
    }

    static convertImageFileToDataUrl(image: File): Observable<string> {
        return new Observable((observer: Observer<string>) => {
            const reader = new FileReader()

            reader.onload = (event: ProgressEvent<FileReader>) => {
                const dataUrl = event.target?.result as string
                observer.next(dataUrl)
                observer.complete()
            }

            reader.onerror = (event: ProgressEvent<FileReader>) => {
                observer.error(event.target?.error)
            }

            reader.readAsDataURL(image)
        })
    }

    static filterCountries(value: string, countries: Country[]): Country[] {
        if (value === null) {
            value = ''
        }
        const filterValue = value.toLowerCase()

        return countries.filter((country) =>
            country.name.toLowerCase().startsWith(filterValue)
        )
    }

    static isValidImageFile(file: File): boolean {
        const allowedExtensions = ['png', 'jpg', 'jpeg']
        const fileExtension = file.name.split('.').pop()?.toLowerCase()
        return allowedExtensions.includes(fileExtension || '')
    }

    static formatAppWriteException(exception: AppwriteException): string {
        const code = exception.code || 500
        const type = exception.type || 'UnknownType'
        const response = exception.response || 'No response data'

        let formattedError = `AppwriteException: ${exception.message}\nCode: ${code}\nType: ${type}\nResponse: ${response}`

        if (exception.name) {
            formattedError += `\nName: ${exception.name}`
        }

        if (exception.stack) {
            formattedError += `\nStack: ${exception.stack}`
        }

        return formattedError
    }

    static formatException(exception: Error): string {
        let formattedError = 'Error: '

        if (exception.name) {
            formattedError += `\nName: ${exception.name}`
        }

        if (exception.message) {
            formattedError += `\nMessage: ${exception.message}`
        }

        if (exception.stack) {
            formattedError += `\nStack: ${exception.stack}`
        }

        return formattedError
    }

    static handleErrorMessage(error: any, correlationId?: string): string {
        let message = ''

        if (error instanceof AppwriteException) {
            message = Utils.formatAppWriteException(error)
        } else {
            message = Utils.formatException(error)
        }

        return correlationId
            ? `[Correlation ID: ${correlationId}] ${message}`
            : message
    }

    static getDirtyFields(formGroup: FormGroup): { [key: string]: any } {
        const dirtyFields: { [key: string]: any } = {}

        Object.keys(formGroup.controls).forEach((key) => {
            const control = formGroup.get(key)

            if (control && control.dirty) {
                dirtyFields[key] = control.value
            }
        })

        return dirtyFields
    }

    static checkForFormChanges(
        initialValues: { [key: string]: any },
        currentValues: FormGroup
    ): boolean {
        // Compare current form values with initial values to identify changes
        const currentFormValues = currentValues.value

        return Object.keys(currentFormValues).some((key) => {
            return initialValues[key] !== currentFormValues[key]
        })
    }

    static isEmptyOrWhiteSpace(str: string) {
        return str.trim().length === 0
    }

    static isEmptyObject(obj: object): boolean {
        if (typeof obj !== 'object' || obj === null) {
            return false
        }

        if (Object.getPrototypeOf(obj) === Object.prototype) {
            // Empty object (no inherited properties)
            return true
        }

        for (const prop in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, prop)) {
                // Object has at least one own property
                return false
            }
        }

        return true
    }

    static formatHttpErrorMessage(error: HttpErrorResponse): string {
        console.error(error)
        return `Http call to ${error.url} returns an error: ${error.message} status: ${error.status} statusText: ${error.statusText}`
    }

    static mapUploadedPhotosToIds(
        uploadedPhotos: any[],
        photos: PhotoInterface[],
        type: PhotoType
    ): string[] {
        return uploadedPhotos
            .filter((uploadedPhoto) =>
                photos.some(
                    (photo) =>
                        photo.id === uploadedPhoto.$id &&
                        photo.photoType === type
                )
            )
            .map((uploadedPhoto) => uploadedPhoto.$id)
    }
    static stringToBoolean(str: string): boolean {
        switch (str.toLowerCase().trim()) {
            case 'true':
            case 'yes':
            case '1':
                return true
            case 'false':
            case 'no':
            case '0':
                return false
            default:
                throw new Error('Invalid boolean string')
        }
    }

    static isNumeric(value: string): boolean {
        return /^\d+$/.test(value)
    }
}
