import { InjectionToken } from '@angular/core'
import {
    Account,
    Client,
    Databases,
    Locale,
    Messaging,
    Storage,
    Teams,
} from 'appwrite'
import { environment } from 'src/environments/environment'

export const APPWRITE_SDK = new InjectionToken<AppwriteSdk>('Appwrite SDK', {
    providedIn: 'root',
    factory() {
        const client = new Client()
        client.setEndpoint(environment.appwrite_url)
        client.setProject(environment.project_id)
        client.setSession('cookie');
        const databases = new Databases(client)
        const account = new Account(client)
        const locale = new Locale(client)
        const storage = new Storage(client)
        const messaging = new Messaging(client)
        const teams = new Teams(client)
        return { databases, account, locale, storage, messaging, teams }
    },
})

export interface AppwriteSdk {
    databases: Databases
    account: Account
    locale: Locale
    storage: Storage
    messaging: Messaging
    teams: Teams
}

// https://angular.io/guide/dependency-injection-providers

// to used as depency injection in the following way:
//  constructor( @Inject(APPWRITE_SDK) private appwrite: AppwriteSdk) {}
