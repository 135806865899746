import { FileInterface } from "src/app/shared/interfaces/file.Interface";

export interface PhotoInterface extends FileInterface {
    dataUrl: any;
    photo: File;
    photoType: PhotoType;
}

export enum PhotoType {
    Masjid = 'masjid',
    Leader = 'leader',
    News = 'news',
    Profile = 'profile',
}