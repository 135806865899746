import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    constructor(
        private _transloco: TranslocoService,
        private toastr: ToastrService,
        private spinner: NgxSpinnerService
    ) {}

    /** Show loading spinner */
    loading(message: string = 'Loading...') {
        this.spinner.show(undefined, {
            type: 'ball-spin',
            bdColor: 'rgba(0,0,0,0.8)',
            color: '#f5b339',
            fullScreen: true,
            template: `<p style="color: white;">${message}</p>`
        });
    }

    /** Remove loading spinner */
    removeLoading() {
        this.spinner.hide();
    }

    /** Show success notification */
    notifySuccess(message: string) {
        this.toastr.success(message, 'Success', {
            timeOut: 3000,
            positionClass: this.ltrPosition(),
            progressBar: true
        });
    }

    /** Show failure notification */
    notifyFailure(message: string) {
        this.toastr.error(message, 'Error', {
            timeOut: 5000,
            positionClass: this.ltrPosition(),
            progressBar: true
        });
    }

    /** Show warning notification */
    notifyWarning(message: string) {
        this.toastr.warning(message, 'Warning', {
            timeOut: 4000,
            positionClass: this.ltrPosition(),
            progressBar: true
        });
    }

    /** Show info notification */
    notifyInfo(message: string) {
        this.toastr.info(message, 'Info', {
            timeOut: 4000,
            positionClass: this.ltrPosition(),
            progressBar: true
        });
    }

    /** Dynamically set notification position based on language direction */
    ltrPosition() {
        return this._transloco.getActiveLang() === 'ar' 
            ? 'toast-top-left' 
            : 'toast-top-right';
    }
}
